<template>
	<div class="row patient_chart">
		<sidebar></sidebar>
		<div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
			<div class="send-fax-details">
				<h1 class="sendfax-h1 mb-30">Send a Fax</h1>
				<div class="sender-fax-fillup">
					<div class="d-flex align-items-center mb-30">
						<p class="sender-title mb-0">SENDER</p>
						<p class="send-mail-id mb-0">{{ this.clinicDetail.email }}</p>
					</div>
				</div>
				<div class="sender-fax-fillup">
					<div class="d-flex mb-30">
						<p class="sender-title mb-0 mt-25">RECIPIENT <span class="text-red">*</span></p>
						<div class="multi-recipient-nmbr w-100">
							<input type="text" v-model="form.recipient" name="" class="form-control mb-15" @input="handleInput" @keydown="handleKeyDown" :maxlength="this.maxLength">
							<span class="multi-rc-fax-nmber white-space-nowrap d-inline-block me-2 mb-2" v-for="(phone, index) in this.form.phoneNumbers" :key="index">
								{{ phone }}
								<img src="/images/close-icon.svg" @click="removeSelectedFax(phone)" class="cursor-pointer ml-10" style="margin-left: calc(var(--scale-ratio) * 10px); height: calc(var(--scale-ratio) * 10px);">
							</span>
							<ValidationErrorMessageList :errors="v$.form.phoneNumbers.$errors" />
							<!-- <p class="separate-with-comma">(use comma to separate for multiple fax recipients. ex:
								647777777, 647123123)</p> -->
						</div>
					</div>
				</div>
				<div class="sender-fax-fillup mt-100">
					<div class="d-flex mb-20">
						<p class="sender-title mb-0">FILES UPLOADED FOR THIS FAX <span class="text-red">*</span></p>
						<div class="row w-100">
							<slot v-for="(fileDetail, index) in this.files" :key="index">
								<div class="col-6 col-sm-6 col-md-6 col-lg-4 position-relative">
								<p class="send-mail-id mb-0">{{ fileDetail.fileName }}</p>
								<!-- Close Button -->
								<button 
									@click="removeFile(index)" 
									class="removeFile-button d-flex align-items-center">
									<img src="images/close-icon.svg" class="">
								</button>
								</div>
							</slot>
						</div>
					</div>
				</div>
				<div class="sender-fax-fillup mb-60">
					<div class="choose-file-part text-center cursor-pointer" @click="triggerFileInput">
						<p class="mb-0">Choose File... <span class="text-red">*</span></p>
					</div>
					<ValidationErrorMessageList :errors="v$.form.file.$errors" />
					<input type="file" id="fileInput" ref="fileInput" @change="handleFileChange" style="display: none;"
						accept=".pdf" multiple />
				</div>
				<div class="text-end mb-30">
					<button class="comman_btn sendfax-btn fs-18" @click="sendFaxModel">Send Fax</button>
				</div>
			</div>
		</div>
	</div>
	<div class="modal fade" id="send-fax-model" tabindex="-1" style="display: none;" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
		<div class="modal-dialog modal-dialog-centered modal-xl sendfax-modal">
			<div class="modal-content auto_height_popup">
				<div class="modal-body adminuser-slide">
					<button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close"></button>
					<div class="box">
						<div class="row">
							<div class="col-12 col-sm-6 col-md-6 col-lg-6">
								<h3 class="fax-settings-md-title mb-40">Fax Settings</h3>
								<div class="row align-items-center mb-30">
									<div class="col-12 col-sm-5 col-md-5 col-lg-5">
										<label class="fax-setting-lable cursor-text">Cover Page Template</label>
									</div>
									<div class="col-12 col-sm-7 col-md-7 col-lg-7">
										<div class="pdf_cover_page_select">
											<CustomDropDown :options="coverPageOpt" :initialValue="form.cover_letter"
												v-model="form.cover_letter" @item-selected="handleItemSelected"
												fieldName="cover_letter" divClass="cover-page-box"></CustomDropDown>
										</div>
									</div>

								</div>
								<div class="row align-items-center mb-30">
									<div class="col-12 col-sm-5 col-md-5 col-lg-5">
										<label class="fax-setting-lable cursor-text">To Name</label>
									</div>
									<div class="col-12 col-sm-7 col-md-7 col-lg-7">
										<div class="pdf_cover_page_select">
											<input type="text" v-model="form.to_name" class="form-control cursor-text">
										</div>
									</div>
								</div>
								<div class="row align-items-center mb-30">
									<div class="col-12 col-sm-5 col-md-5 col-lg-5">
										<label class="fax-setting-lable cursor-text">Organization</label>
									</div>
									<div class="col-12 col-sm-7 col-md-7 col-lg-7">
										<div class="pdf_cover_page_select">
											<input type="text" v-model="form.organization" class="form-control cursor-text">
										</div>
									</div>
								</div>
								<div class="row align-items-center mb-30">
									<div class="col-12 col-sm-5 col-md-5 col-lg-5">
										<label class="fax-setting-lable cursor-text">From</label>
									</div>
									<div class="col-12 col-sm-7 col-md-7 col-lg-7">
										<p class="mb-0 from-brand-name">{{ this.clinicDetail.organization_name }}</p>
									</div>
								</div>
								<div class="row align-items-center mb-30">
									<div class="col-12 col-sm-5 col-md-5 col-lg-5">
										<label class="fax-setting-lable cursor-text">Subject</label>
									</div>
									<div class="col-12 col-sm-7 col-md-7 col-lg-7">
										<div class="pdf_cover_page_select">
											<input type="text" v-model="form.subject" class="form-control cursor-text">
										</div>
									</div>
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-6 col-lg-6">
								<div class="fax-setting-preview position-relative">
									<div class="left-side-arrow">
										<img src="images/down-arrow_l.svg" class="cursor-pointer" v-if="currentPage > 1" @click="goToPreviousPage">
									</div>
									<div class="text-center">
										<canvas id="pdfCanvas" ref="pdfCanvas" class="w-100"></canvas>
										<!-- <img src="/images/prescription-rx.png"> -->
									</div>
									<div class="right-side-arrow">
										<img src="images/down-arrow_r.svg" class="cursor-pointer" v-if="currentPage < totalPages" @click="goToNextPage">
									</div>
								</div>
							</div>
						</div>
						<div class="btn_part text-center mt-50">
							<button class="comman_brdr_btn big_btn mx40" @click="cancelModel">Cancel</button>
							<button class="comman_btn big_btn mx40" @click="viewPdfInNewTab">Print <img src="images/loader.gif"
									v-if="this.loaderPrint" style="width: calc(var(--scale-ratio) * 18px);" /></button>
							<button class="comman_btn big_btn mx40" @click="sendFax">Fax <img src="images/loader.gif"
									v-if="this.loader" style="width: calc(var(--scale-ratio) * 18px);" /></button>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>
<script>
import sidebar from './sidebar.vue';
import axios from "@/scripts/axios.js";
import useVuelidate from "@vuelidate/core";
import ValidationErrorMessageList from '../../components/base/ValidationErrorMessageList.vue';
import CustomDropDown from '../base/formFields/CustomDropDown.vue';
import { PDFDocument } from 'pdf-lib';
import _ from 'lodash';

export default {
	setup: () => {
		return { v$: useVuelidate() };
	},
	data() {
		return {
			files: [],
			selectedFiles: [],
			clinicDetail:[],
			userFaxConfigDetail:[],
			coverPageOpt: [
				// { value: "Not Set", title: "Not Set" },
				{ value: "Basic", title: "Basic" },
				// { value: "Standard", title: "Standard" },
				// { value: "Company", title: "Company" },
				// { value: "Personal", title: "Personal" },
			],
			isDisabled:true,
			existingPdfBytes: null,
			pdfDoc: null,
			totalPages: 0,
			currentPage: 1,
			loader:false,
			loaderPrint:false,
		}
	},
	components: {
		sidebar,
		ValidationErrorMessageList,
		CustomDropDown
	},
	computed: {
		form() {
			return this.$store.state.sendFax.form;
		},
		maxLength() {
			return (this.form.recipient && this.form.recipient.includes("-")) ? 12 : 10;
		},
	},
	created(){
		this.getClinicDetail();
		this.debounceFormatFaxNumber = _.debounce(this.formatFaxNumber, 300); // Wait 300ms after typing stops
	},
	methods: {
		getClinicDetail(){
			axios.post("admin/clinic/retrieve")
				.then((response) => {
					if (response.status == 200) {
						this.clinicDetail = response.data.data
					}else {
						this.$filters.moshaToast(response.data.message, "error");
					}
				}).catch(error => {
					if (error.response.status === 422) {
						this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
					} else {
						this.$filters.moshaToast(error.response.data.message, "error");
					}
				});
		},
		async sendFaxModel() {
			this.v$.form.$validate();
			if (!this.v$.form.$error) {

				// Read files as ArrayBuffer
				const fileArrayBuffers = await Promise.all(
					this.selectedFiles.map(file => this.readFileAsArrayBuffer(file))
				);

				const mergedPdfBytes = await this.mergePdfs(fileArrayBuffers);

				// Directly assign the merged PDF bytes
				this.existingPdfBytes = mergedPdfBytes;

				// Load the merged PDF
				this.pdfDoc = await PDFDocument.load(this.existingPdfBytes, { ignoreEncryption: true });

				this.totalPages = this.pdfDoc.getPageCount();
				this.renderPage(this.currentPage);

				axios.post("admin/fax-config/retrieve")
				.then((response) => {
					if (response.status == 200) {
						this.userFaxConfigDetail = response.data.data
						// this.form.cover_letter = this.userFaxConfigDetail.cover_letter
						window.$("#send-fax-model").modal("show");


					}else {
						this.$filters.moshaToast(response.data.message, "error");
					}
				}).catch(error => {
					if (error.response.status === 422) {
						this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
					} else {
						this.$filters.moshaToast(error.response.data.message, "error");
					}
				});
			}
		},
		handleFileChange(event) {
			this.files = [];
			const files = Array.from(event.target.files);
			if (files.length > 0) {
				const newFiles = Array.from(event.target.files); // Convert FileList to Array

				// Add new files to the selectedFiles array without duplicating
				newFiles.forEach((file) => {
					if (!this.selectedFiles.find((f) => f.name === file.name)) {
						this.selectedFiles.push(file);
					}
				});
				this.form.file = this.selectedFiles

				this.selectedFiles.forEach((file) => {
					let type = null
					if (file.type.startsWith('image/')) {
						type = 'image';
					} else if (file.type === 'application/pdf') {
						type = 'pdf';
					}

					const fileExists = this.files.some(f => f.fileName === file.name);
					if (!fileExists) {
						this.files.push({ 'fileName': `${file.name}`, 'fileUrl': URL.createObjectURL(file), 'type': type })
					} else {
						console.log("File already exists, not pushing to the array.");
					}
				});

				this.$refs.fileInput.value = null;
			}
		},
		removeFile(index) {
			this.files.splice(index, 1);
			this.selectedFiles.splice(index, 1);
		},
		triggerFileInput() {
			this.$refs.fileInput.click();
		},
		sendFax() {
			this.v$.form.$validate();
			if (this.v$.form.$error) {
				for (const [key] of Object.entries(this.v$.form)) {
					if (this.v$.form[key].$errors && this.v$.form[key].$errors.length > 0) {
						const myEl = document.getElementById(key);
						if (myEl) {
							this.$smoothScroll({
								scrollTo: myEl,
							});
							break;
						}
					}
				}

				return true
			}

			this.loader = true
			const formData = new FormData();

			// Append the rest of the form data to the FormData object
			for (let key in this.form) {
				if (typeof this.form[key] === 'object' && this.form[key] !== null) {
					if (key == 'phoneNumbers') {
						formData.append('recipient', this.form[key].join(', '));
					} else {
						for (let subKey in this.form[key]) {
							formData.append(`${key}[${subKey}]`, this.form[key][subKey]);
						}
					}
				} else if (this.form[key]) {
					formData.append(key, this.form[key]);
				}
			}

			const config = {
				headers: {
					'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
					'Content-Type': 'multipart/form-data'
				},
			};

			axios.post("inbox/send-fax", formData, config)
				.then((response) => {
					if (response.status == 200) {
						this.loader = false
						if (response.data.message == 'Error') {
							response.data.data.forEach((error) => {
								this.$filters.moshaToast(error, "error");
							});
						} else {
							this.resetForm()
							this.$filters.moshaToast(response.data.message, "success")
							window.$("#send-fax-model").modal("hide");
						}
					} else {
						this.loader = false
						this.$filters.moshaToast(response.data.message, "error");
					}
				}).catch(error => {
					this.loader = false
					if (error.response.status === 422) {
						this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
					} else {
						this.$filters.moshaToast(error.response.data.message, "error");
					}
				});
		},
		printFax(){

		},
		handleItemSelected(values) {
			this.form[values.fieldName] = values.item.value
		},
		cancelModel(){
			window.$("#send-fax-model").modal("hide");
			this.resetForm()
		},
		resetForm(){
			Object.assign(
				this.$store.state.sendFax.form,
				this.$store.state.sendFax.defaultFormData,
			);
			this.form.phoneNumbers = []
			this.v$.$reset();
			this.v$['form'].$reset();
			if (this.$refs.fileInput) {
				this.$refs.fileInput.value = null;
			}
			this.files = [];
			this.selectedFiles = [];

		},
		async renderPage(pageNumber) {
			try {
				const loadingTask = window.pdfjsLib.getDocument({ data: this.existingPdfBytes });
				const pdf = await loadingTask.promise;
				const page = await pdf.getPage(pageNumber);

				const canvas = this.$refs.pdfCanvas;
				const context = canvas.getContext('2d');

				// Set canvas dimensions to match the page at 100% scale
				const viewport = page.getViewport({ scale: 1 });
				canvas.width = viewport.width;
				canvas.height = viewport.height;

				// Render the page on the canvas
				const renderContext = {
					canvasContext: context,
					viewport: viewport,
				};
				await page.render(renderContext).promise;
			} catch (error) {
				console.error('Error rendering page:', error);
			}
		},
		goToNextPage() {
			if (this.currentPage < this.totalPages) {
				this.currentPage++;
				this.renderPage(this.currentPage);
			}
		},
		goToPreviousPage() {
			if (this.currentPage > 1) {
				this.currentPage--;
				this.renderPage(this.currentPage);
			}
		},
		async mergePdfs(pdfBytesArray) {
			const mergedPdf = await PDFDocument.create();

			for (const pdfBytes of pdfBytesArray) {
			try {
				const pdfDoc = await PDFDocument.load(pdfBytes, { ignoreEncryption: true });
				const pageIndices = pdfDoc.getPageIndices();

				for (const index of pageIndices) {
				const [copiedPage] = await mergedPdf.copyPages(pdfDoc, [index]);
				mergedPdf.addPage(copiedPage);
				}
			} catch (error) {
				console.error('Error loading or merging PDF:', error);
			}
			}

			const mergedPdfBytes = await mergedPdf.save();
			return mergedPdfBytes;
		},
		readFileAsArrayBuffer(file) {
			return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.onload = () => resolve(reader.result);
				reader.onerror = reject;
				reader.readAsArrayBuffer(file);
			});
		},
		async viewPdfInNewTab() {
			// Convert the PDF bytes to a Blob
			const blob = new Blob([this.existingPdfBytes], { type: 'application/pdf' });

			// Create a URL for the Blob
			const url = URL.createObjectURL(blob);

			// Open the Blob URL in a new tab
			window.open(url, '_blank');

		},
		handleInput() {
			if (this.form.phoneNumbers.length >= 5) {
				// If the limit is exceeded, use the debounced version
				this.debounceFormatFaxNumber();
			} else {
				// Otherwise, call the method directly
				this.formatFaxNumber();
			}
		},
		handleKeyDown(event) {
			if (event.key == 'Backspace' && this.form.recipient) {
				this.form.recipient = this.form.recipient.slice(0, -1);
				event.preventDefault();
            }
			if (this.form.recipient && this.form.recipient.length >= this.maxLength){
				event.preventDefault();
			}
        },
		formatFaxNumber() {
			const { recipient, phoneNumbers } = this.form;

			if(this.form.phoneNumbers.length >= 5 && this.form.recipient) {
				this.$filters.moshaToast("You cannot add more than five recipient fax numbers for sending.", "error");
				this.form.recipient = '';
				return;
			}

			// Ensure no trailing commas and trim input
			if (recipient.endsWith(',')) {
				this.form.recipient = recipient.slice(0, -1).trim();
				return;
			}

			// Format the fax numbers
			const result = this.formatFaxWithCheckDuplicateNumber(recipient, phoneNumbers);
			
			// Show the toast message, if try to add duplicate fax number
			if (result.hasDuplicates) {
				this.$filters.moshaToast("You cannot add recipient duplicate fax numbers.", "error");
			}

			// Update the model
			this.form.recipient = result.faxNumber;
			this.form.phoneNumbers = result.phoneNumbers;
		},
		formatFaxWithCheckDuplicateNumber(faxNumber, phoneNumbers) {
			let faxRegex = /^(1[ .-]?)?(\d{3}|\(\d{3}\))[ .-]?\d{3}[ .-]?\d{4}$/; // Pattern for fax numbers
			faxNumber = faxNumber.trim(); // Trim spaces
			
			let faxNumbersArray = faxNumber.split(',').map(fax => fax.trim()); // Split input by comma

			// Check for invalid numbers if the last character is a comma
			if (faxNumber.endsWith(',')) {
				const lastFaxNumber = faxNumbersArray[faxNumbersArray.length - 2];
				if (lastFaxNumber && lastFaxNumber.length === 0 || !faxRegex.test(lastFaxNumber)) {
					faxNumbersArray.splice(faxNumbersArray.length - 2, 1); // Remove invalid number
					faxNumber = faxNumbersArray.join(', ');
					return { faxNumber, phoneNumbers };
				}
			}

			// Format and validate fax numbers
			const formattedFaxNumbers = faxNumbersArray.map(number => {
				const numericFax = number.replace(/\D/g, ""); // Remove non-numeric characters
				if (numericFax.length >= 3 && numericFax.length <= 6) {
					return `${numericFax.slice(0, 3)}-${numericFax.slice(3)}`;
				} else if (numericFax.length > 6) {
					return `${numericFax.slice(0, 3)}-${numericFax.slice(3, 6)}-${numericFax.slice(6, 10)}`;
				}
				return number; // Keep it as is if length < 3
			});

			let remainingFaxNumbers = [];
			let hasDuplicates = false;

			// Check for duplicates and validate numbers
			formattedFaxNumbers.forEach(formattedFax => {
				if (faxRegex.test(formattedFax)) {
					if (phoneNumbers.includes(formattedFax)) {
						hasDuplicates = true; // Detect duplicates
					} else {
						phoneNumbers.push(formattedFax); // Add valid number
					}
				} else {
					remainingFaxNumbers.push(formattedFax); // Store invalid numbers
				}
			});

			// Remove duplicates and sort numbers
			phoneNumbers = Array.from(new Set(phoneNumbers)).sort((a, b) => {
				const numA = a.replace(/\D/g, '');
				const numB = b.replace(/\D/g, '');
				return numB.localeCompare(numA);
			});

			// Join remaining invalid numbers back with commas
			faxNumber = remainingFaxNumbers.join(', ');

			return { faxNumber, phoneNumbers, hasDuplicates };
		},
		showToast(message) {
			this.$filters.moshaToast(message, "error");
		},
		removeSelectedFax(numberToRemove) {
			this.form.phoneNumbers = this.form.phoneNumbers.filter(phone => phone !== numberToRemove);

			// If the removed number brings the count below 5, allow editing again
			if (this.form.phoneNumbers.length < 5) {
				// this.form.recipient = '';
			}
		},
	},
	validations() {
		return this.$store.state.sendFax.validationRules
	},
	mounted() {
		this.resetForm()

		const pdfScript = document.createElement('script');
		pdfScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.14.305/pdf.min.js';
		// Letest
		// pdfScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.1.81/pdf.min.js';
		// client
		// pdfScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.6.172/pdf.min.js';
		pdfScript.onload = () => {
			const workerScript = document.createElement('script');
			workerScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.14.305/pdf.worker.min.js';
			// Letest
			// workerScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.1.81/pdf.worker.min.js';
			// client
			// workerScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf-lib/1.17.1/pdf-lib.min.js';
			workerScript.onload = () => {
				// this.initializePdf();
			};
			document.head.appendChild(workerScript);
		}
		document.head.appendChild(pdfScript);
	}
}
</script>