<template>
  <div class="search_drop_box" :class="{'advance-filter-optn': loopCount.length > 4}">
    <div v-for="index in loopCount" :key="index">
      <CustomFilterEle
        :key="elementKey"
        :options="options"
        :fieldName="index"
        :initialValue="initialValue"
        @array-updated="handleArrayUpdate"
        @text-updated="handleTextUpdate"
        @remove-item="handleRemoveFilEle"
        @remove-empty-item="handleRemoveUnselectedOpt"
        :selectedOpt="selectedOpt"
        @request-filter="refreshData"
      />
    </div>
    <div @click="addFilterEle" class="text-center plus_btn" v-if="selectedOpt.length < options.length">+</div>
  </div>
</template>

<script>
import CustomFilterEle from '../base/CustomFilterEle.vue';

export default {
  data() {
    return {
      componetsRenderCnt: 1, // Number of times the loop runs
      selectedFieldNames: [],
      selectedFields: [],
      elementKey: 0,
    };
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    fieldName: {
      type: String,
      required: true,
    },
    initialValue: {
      type: String,
      required: true,
    },
    selectedOpt: {
      type: Array,
      required: true,
    },
  },
  components: {
    CustomFilterEle,
  },
  methods: {
    addFilterEle() {
        if ((this.selectedFieldNames.length == 0 || !this.selectedFieldNames[this.loopCount.length - 1]) && this.loopCount.length > 0){
          this.$filters.moshaToast("Please select filter options", "error");
          return false
        }


        this.componetsRenderCnt++;
    },
    updateFieldNames(fieldIndex, value) {
      // Ensure the field exists in selectedFieldNames, and then set both fieldlabel and value
      if (!this.selectedFieldNames[fieldIndex]) {
        this.selectedFieldNames[fieldIndex] = {};
      }
      
      // Get the field label from selectedFields
      let field = this.selectedFields[fieldIndex];
      
      // Update or set the field and its value in selectedFieldNames
      this.selectedFieldNames[fieldIndex] = { field, value };
      this.selectedFields[fieldIndex] = field;
    },
    handleArrayUpdate({ field, value }) {
      // Update fields based on array data
      this.selectedFields[field] = value;
      if (this.selectedFieldNames && this.selectedFieldNames.some(element => element.value != '')) {
      this.selectedFieldNames.forEach(element => {
          if (element.value != '') {
            this.updateFieldNames(field, value);
            this.emitCleanedFieldNames();
          }
        });
      }
    },
    handleTextUpdate({ field, value }) {
      // Update fields based on text data
      if (value == field) {
        value = ''
      }

      this.updateFieldNames(field, value);
      this.emitCleanedFieldNames();
      console.log(this.selectedOpt)
    },
    handleRemoveFilEle(value) {
      this.selectedFieldNames = value;
      this.emitCleanedFieldNames();
      this.componetsRenderCnt--;
      this.elementKey++;
    },
    handleRemoveUnselectedOpt(fieldIndex) {
      this.selectedFields.splice(fieldIndex, 1);
      this.selectedFieldNames.splice(fieldIndex, 1);
      this.emitCleanedFieldNames();
      this.componetsRenderCnt--;
      this.elementKey++;
    },
    emitCleanedFieldNames() {
      // Emit the cleaned field names list
      const cleanedFieldNames = this.selectedFieldNames.filter(item => item !== null && item.value !== 'None');
      this.$emit('custom-filter', cleanedFieldNames);
    },
    refreshData() {
      this.$emit('refresh-data');
    },
  },
  computed: {
    loopCount() {
      return Array.from({ length: this.componetsRenderCnt }, (_, index) => index);
    },
  },
  created() {
    this.componetsRenderCnt = this.selectedOpt.length || 1;
  },
};
</script>
