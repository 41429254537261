<template>
	<div class="PatientChart_header">
		<div class="row align-items-center">
			<div class="col-md-3 col-sm-3 col-3">
				<span class="logo">{{ headerTitle }}</span>
			</div>
			<div class="col-md-9 col-sm-9 col-9" style="height: calc(var(--scale-ratio) * 42px);">
				<div class="d-flex align-items-center justify-content-end">
					<slot v-if="this.$route.name === 'Inbox' || this.$route.name === 'InboxDeletedList' || this.$route.name === 'FaxesSent' || this.$route.name === 'SentAFax' || this.$route.name === 'InboxEdit'">
						<p class="current-usage-tag-line mb-0 mt-10"><span>current usage:</span> {{this.currentUsage}} / 500 Pages ({{this.currentUsagePer}}%)</p>
					</slot>
					<slot v-if="this.$route.name === 'auditLogs'">
						<p class="current-usage-tag-line mb-0 mt-10"><span>Audit Logs will be cleared after 90 days</span></p>
					</slot>
					<slot v-if="isLoginUserDetailDisplay">
						<p class="current-usage-tag-line mb-0 mt-10"><span>currently logged in as:</span> {{ loggedInUser }}</p>
					</slot>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "@/scripts/axios.js";

export default {
	components: {},
	data() {
		return {
			currentUsage:0,
			currentUsagePer:0,
			loggedInUser: '', // To store the user information
		};
	},
	props: {
		headerTitle: {
			type: String,
			required: true
		},
	},
	validations() { },
	created() {
		// this.fetchSRFaxRecords()
		this.getCurrentUsage()
	},
	methods: {
		cancelBtn() {
			this.$router.push(
				'/'
			);
		},
		getCurrentUsage(){
			axios.post('inbox/get-fax-usage')
				.then((response) => {
					if (response.status == 200) {
						let detail = response.data.data
						if (detail) {
							this.currentUsage = detail.NumberOfPages
							this.currentUsagePer = ((this.currentUsage / 500) * 100).toFixed(2)
						}
					}
				})
		},
		fetchSRFaxRecords() {
			this.$store.state.loader = true;
			axios.post('admin/fax-config/list')
				.then((response) => {
					this.$store.state.loader = false;
					if (response.status == 200) {
						if (response.data.data) {
							let detail = response.data.data;
							this.currentUsage = detail.plan_usage_per_month
							this.currentUsagePer = ((this.currentUsage / 500) * 100).toFixed(2)
						} else {
							this.$store.state.fax.form = this.$store.state.user_site_details.defaultFormData;
						}
					} else {
						this.$filters.moshaToast(response.data.message, "error");
					}
				}).catch(error => {
					this.$store.state.loader = false;
					if (error.response.status === 422) {
						this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
						console.log(Object.values(error.response.data.data).join(', '))
					} else {
						this.$filters.moshaToast(error.response.data.message, "error");
					}
				})
		},
	},
	computed:{
		isLoginUserDetailDisplay() {
			const currentUrl = window.location.href;
			return currentUrl.includes('administration') && this.$route.name !== 'auditLogs';
		}
	},
	mounted(){
		// Retrieve user information from localStorage when the component is mounted
		const userName = localStorage.getItem('authUserName');
		const userRole = localStorage.getItem('authUserRole');
		let authUserName = userName ? userName : '';
		let authUserRole = userRole ? userRole : '';

		this.loggedInUser = authUserName+" ("+authUserRole+")";
	}
};
</script>
